class StoryblokService {
    initEditor(componentInstance) {
        if (window.storyblok) {
            window.storyblok.init();
            window.storyblok.on('input', event => {
                // This subscriber is required to get all changes
                // in the CMS editor on the fly
                if (event.story.content._uid === componentInstance.article.content._uid) {
                    componentInstance.article = event.story;
                }
            });
        }
    }
}
const storyblokInstance = new StoryblokService();
export default storyblokInstance;
