import { defineComponent } from '@vue/composition-api';
import RoundedButton from '@/shared/components/form/RoundedButton.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
export default defineComponent({
    components: {
        RoundedButton
    },
    props: {
        fallback: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root } = context;
        const back = () => {
            const locale = root.$route.params.locale || '';
            const prefix = locale ? `${locale}-` : '';
            if (props.fallback) {
                const params = ROUTE_LIST_NAMES[props.fallback]
                    ? { name: `${prefix}${ROUTE_LIST_NAMES[props.fallback]}` }
                    : { path: props.fallback };
                globalThis.$router.push(params);
            }
            else
                globalThis.$router.back();
        };
        return {
            back
        };
    }
});
