import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    filters: {
        utm: function (value, network, blog) {
            return `${value}?utm_source=visitor&utm_medium=${network}&utm_campaign=blogshare&utm_id=blog_${encodeURIComponent(blog.replaceAll(' ', '_').toLowerCase())}}`;
        },
        newlines: function (value) {
            return value + '\n\n';
        }
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        },
        isOnBottom: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const options = [
            { name: 'linkedin', iconName: 'linkedin', social: true },
            { name: 'twitter', iconName: 'x', social: true },
            { name: 'facebook', iconName: 'facebook', social: true },
            { name: 'pinterest', iconName: 'pinterest', social: true }
        ];
        const sharedData = computed(() => {
            return {
                name: props.data.title,
                description: props.data.description,
                link: props.data.link
            };
        });
        const onSocialShareButtonClick = socialNetworkName => {
            if (!socialNetworkName || !sharedData.value)
                return;
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'blog_share',
                category: 'social',
                action: 'blog_share_social',
                label: 'User has shared a blog article',
                value: socialNetworkName,
                noninteraction: false
            });
        };
        return {
            options,
            sharedData,
            onSocialShareButtonClick
        };
    }
});
