import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    setup() {
        return {
            breakpoints: {
                960: {
                    visibleSlides: 2
                },
                375: {
                    visibleSlides: 1
                }
            },
            logos: [
                {
                    id: 1,
                    title: 'NRC',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/nrc_AeRTU5dk2.png?tr=w-167,h-50'
                },
                {
                    id: 2,
                    title: 'Financieel Dagblad',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/fd_zbTFo1Wtl.png?tr=w-70,h-50'
                },
                {
                    id: 3,
                    title: 'Elsevier',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/elsevier_ng02k6SUB.png?tr=w-50,h-50'
                },
                {
                    id: 4,
                    title: 'RTV Noord Holland',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/rtvnh_x0_UPZ7lg.png?tr=w-64,h-50'
                },
                {
                    id: 5,
                    title: 'In Control Magazine',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/incontrol_7qccZhVfxB.png?tr=w-166,h-26'
                },
                {
                    id: 6,
                    title: 'Kerkvernieuwers',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/kerkvernieuwers_TVlYqduux7.png?tr=w-166,h-28'
                },
                {
                    id: 7,
                    title: 'Leger Des Heils',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/leger_1NyhzdFuYn.png?tr=h-50'
                },
                {
                    id: 8,
                    title: 'Inspectie Justitie en Veiligheid',
                    filename: 'https://media.wemeet.nl/gallery/assets/trustitem_brands/ministerie_veiligheid_en_justitie_uQ8StYqM4E.png?tr=h-50,w-165'
                }
            ]
        };
    }
});
